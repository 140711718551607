import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanCargoRollJournal = _resolveComponent("PlanCargoRollJournal")!
  const _component_PlanCargoPalletJournal = _resolveComponent("PlanCargoPalletJournal")!
  const _component_PlanCargoJournal = _resolveComponent("PlanCargoJournal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.useGroupByCargo && _ctx.isShowCargoRoll)
      ? (_openBlock(), _createBlock(_component_PlanCargoRollJournal, {
          key: 0,
          parentModel: _ctx.parentModel,
          isCalculation: _ctx.isCalculation,
          isSettingsOpen: _ctx.isSettingsOpen
        }, null, 8, ["parentModel", "isCalculation", "isSettingsOpen"]))
      : _createCommentVNode("", true),
    (!_ctx.useGroupByCargo && _ctx.isShowCargoPallet)
      ? (_openBlock(), _createBlock(_component_PlanCargoPalletJournal, {
          key: 1,
          parentModel: _ctx.parentModel,
          isCalculation: _ctx.isCalculation,
          isSettingsOpen: _ctx.isSettingsOpen
        }, null, 8, ["parentModel", "isCalculation", "isSettingsOpen"]))
      : _createCommentVNode("", true),
    (_ctx.useGroupByCargo)
      ? (_openBlock(), _createBlock(_component_PlanCargoJournal, {
          key: 2,
          parentModel: _ctx.parentModel,
          isCalculation: _ctx.isCalculation,
          isSettingsOpen: _ctx.isSettingsOpen
        }, null, 8, ["parentModel", "isCalculation", "isSettingsOpen"]))
      : _createCommentVNode("", true)
  ]))
}