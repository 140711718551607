
import { defineComponent } from "vue";
import store from "@/store";
import PlanCargoJournal from "@/components/journals/PlanCargoJournal/PlanCargoJournal.vue";
import PlanCargoRollJournal from "@/components/journals/PlanCargoJournal/PlanCargoRollJournal.vue";
import PlanCargoPalletJournal from "@/components/journals/PlanCargoJournal/PlanCargoPalletJournal.vue";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    PlanCargoRollJournal,
    PlanCargoPalletJournal,
    PlanCargoJournal,
  },
  props: {
    //модель родительского элемента
    parentModel: { required: true },
    isCalculation: { required: true },
    isSettingsOpen: { required: true },
  },
  methods: {},
  data() {
    return {
      useGroupByCargo: false,
      showRollLoading: false,
      showPalletLoading: false,
    };
  },
  created() {
    const settings = (store as any).state.settings.settings;

    if (settings) {
      this.useGroupByCargo = settings.useGroupByCargo;
    }
  },
  computed: {
    isShowCargoRoll() {
      return ability.can("view", "Modules.CargoRoll") && ability.can("view", "Documents.PlanCargoRoll");
    },
    isShowCargoPallet() {
      return ability.can("view", "Modules.CargoPallet") && ability.can("view", "Documents.PlanCargoPallet");
    },
  },
});
